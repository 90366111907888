export default {
    title: "Клетки",
    field: "Клетка",
    text: "Каждое поле уникальное. После указания культуры и сроков посева в данном разделе вы получите максимум информации о состоянии Вашего поля.",
    info: {
        crops: "Выращиваемая культура",
        crop: "Культура",
        name_farmer: "Имя агронома",
        num: "Номер",
        area: "Площадь",
        season: "Сезон",
        sort: "Сорт",
        reproduction: "Репродукция",
        date: "Дата посева",
        date_cleaning: "Дата уборки",
        vegetation: "Вегетационный период",
        data: "Данные за",
        settings: "Параметры доступа"
    },
    edit: {
        title: "Редактирование паспорта",
        name: "ФИО агронома | Имя",
        area: "Площадь, га"
    },
    history: {
        title: "История поля",
        predecessor: "Предшественник:",
        yield: "Урожайность:",
        steam: "Пары",
        methods: "Способы обработки",
        year: "Год",
        edit: "Изменить историю",
        add: "Добавить историю"
    },
    indicators: {
        title: "Показатели",
        indicator: "Показатель",
        phosphorus: "Фосфор",
        potassium: "Калий",
        nitrogen: "Азот",
        sulfur: "Сера",
        humus: "Гумус",
        info: {
            notes: "Сравнивая прошлые снимки, наша система обноружила проблемные зоны. Советуем записать себе в заметку.",
            empty: "У Вас пока нет обработанных заказов"
        },
        data: {
            title: "Данные",
            data_from: "Данные из",
            types: ["Хроника сравнения данных", "Проблемные зоны", "Уровень засоренности"]
        },
        indicators: {
            vegetation: {
                title: "Вегетация",
                text: "«Карта оценки объема биомассы» или <br>«вегетационный индекс» позволяет <br>оценивать состояние растений как в <br>условиях густого растительного покрова, так <br>и в условиях разреженной растительности.<br> Для выполнения данной задачи <br>использовался нормализованный относительный индекс растительности <br>(NDVI), который является одним из самых <br>распространенных и используемых индексов <br>для количественной оценки растительного <br>покрова."
            },
            nitrogen: {
                title: "Азот",
                text: "«Карта содержания азота в листьях <br>растений» позволяет обнаружить <br>проблемные зоны состояния посевов в <br>пределах границ кадастрового поля, а также <br>в качестве исходного параметра в моделях <br>урожайности сельскохозяйственных культур"
            },
            humidity: {
                title: "Влажность",
                text: "«Карта содержания влаги» или «индекс <br>влажности поверхности почвы» позволяет <br>оценить неоднородность степени <br>увлажнения растительности и почв. Данный <br>индекс более чувствителен к содержанию <br>влаги в почве и листьях растений. <br>Для выполнения данной задачи <br>использовался индекс влажности почвы и <br>растительности. Нормализованный <br>разностный водный индекс показатель <br>содержания влаги в почве и листьях <br>растений."
            },
            chlorophyll: {
                title: "Хлорофил",
                text: "«Карта содержания хлорофилла в растениях» <br>или «индекс здоровья растительности» <br>позволяет обнаружить проблемные зоны <br>состояния посевов в пределах границ <br>кадастрового поля, а также в качестве <br>исходного параметра в прогнозировании <br>урожайности сельскохозяйственных культур. <br>Уровень хлорофилла указывает на фазу <br>фотосинтеза, в которой находится растение, <br>что в свою очередь указывает на зрелость <br>растения."
            }
        },
        fertilization: {
            title: "Внесение удобрений",
            map: "Карта для внесения азотных удобрений",
            vegetation_zone: "зона вегетации",
            avg_value: "среднее значение",
            rate: "норма внесения",
            total: "Всего",
            mass: "кг удобрения"
        },
        report: {
            title: "Отчет",
            problem_zones: "Отчет по Проблемным зонам",
            weediness: "Отчет по Уровням засоренности",
            pz_n_w: "Отчет по Проблемным зонам и Уровням засоренности"
        }
    },
    chronicle: {
        title: "Хроника",
        by_orders: "По заказам",
        by_weather: "По погоде",
        not_show: "Не показывать"
    },
    yield: {
        title: "Урожайность",
        type: "Введите урожайность",
        forecast: "Прогноз урожайности"
    },
    documents: {
        title: "Документы",
        add: "Добавить документ"
    },
    notes: {
        title: "Заметки",
        empty: "Нажмите на “+” и кликните на карте мышкой, чтобы создать заметку",
        date: "Дата",
        time: "Время",
        note_addition: {
            title: "Добавление заметки",
            problem: {
                title: "Обнаруженная проблема",
                type1: "Болезнь",
                type2: "Вредитель",
                type3: "Сорняк",
                type4: "Другие"
            },
            note: {
                title: "Текст заметки",
                text: "Начните писать..",
                notice: "Упомянуть сотрудника",
                search: "Поиск сотрудника",
                table_note: "Примечание (скорость ветра, облачность, температура, тип опрыскивателя и т.д.)"
            },
            problem_details: {
                type1: {
                    title: "Вид болезни",
                    epv: "ЭПВ, фаза",
                    indicator: "Развитие, (%)",
                    input1: {
                        placeholder: "Выберите болезнь"
                    },
                    input2: {
                        header: "Распространение, %",
                        placeholder: "Введите процент развития"
                    },
                    advice: {
                        header: "Фунгицид",
                        placeholder: "Выберите фунгицид"
                    },
                    add: "Добавить болезнь",
                    add_prep: "Добавить фунгицид"
                },
                type2: {
                    title: "Вид вредителя",
                    epv: "ЭПВ, шт/м²",
                    indicator: "Заселение, (шт/м²)",
                    input1: {
                        placeholder: "Выберите вид вредителя"
                    },
                    input2: {
                        header: "Заселение, шт/м²",
                        placeholder: "Введите показатель"
                    },
                    advice: {
                        header: "Инсектицид",
                        placeholder: "Выберите инсектицид"
                    },
                    add: "Добавить вид вредителя",
                    add_prep: "Добавить инсектицид"
                },
                type3: {
                    title: "Вид сорняка",
                    epv: "ЭПВ, шт/м²",
                    indicator: "Степень засоренности (Х)",
                    input1: {
                        placeholder: "Выберите вид сорняка"
                    },
                    input2: {
                        header: "Степень засоренности",
                        placeholder: "Выберите степень засоренности"
                    },
                    input3: {
                        header: "Количество, м²",
                        placeholder: "Введите значение"
                    },
                    advice: {
                        header: "Гербициды",
                        placeholder: "Выберите гербицид"
                    },
                    add: "Добавить вид сорняка",
                    add_prep: "Добавить гербицид"
                },
                advice_title: "Советы по обработке"
            },
            treatment_date: "Сроки обработки",
            treatment_method: "Способ обработки",
            photo: "Фотографии",
            photo_info: "Информация о фотографии",
            added_by: "Добавил",
            added_from: "Добавлено из",
            from_pc: "ПК",
            from_phone: "Телефона",
            date: "Дата объезда",
            phen_stage: "Фенофаза",
            send: "Отправить уведомление",
            create: "Создать мероприятие по заметке"
        },
        reply: {
            title: "Ответ к заметке",
            text: "Текст ответа",
            from: "Ответ от"
        },
        note_editing: "Редактирование заметки",
        note_details: {
            title: "Заметка",
            consumption_rate: "Норма расхода",
            processing_method: "Метод обработки",
            target: "Объект ",
            creation_date: "Дата заполнения"
        }
    },
    tasks: {
        title: "Задачи",
        apply: "Применить к клетке",
        without_crop: "Без культуры",
        event: "Мероприятие",
        event_dates: "Сроки мероприятия",
        field_num: "Задачи по клетку #",
        event_addition: {
            title: "Новое мероприятие",
            general: {
                title: "Основные категории",
                name: "Название мероприятия",
                date: "Дата мероприятия",
                cost: "Стоимость мероприятия"
            },
            additional: {
                title: "Дополнительные категории",
                machine: "Агрегат",
                speed_limit: "Лимит скорости",
                staff: "Персонал",
                output: "Выработка",
                fuel: "Горючее",
                name: "Наименование",
                amount: "кол-во",
                unit: "ед. изм",
                send: "Отправить в телематику:"
            }
        },
        event_change: {
            edit: "Редактировать мероприятие",
            new: "Новое мероприятие",
            update: "Обновить мероприятие",
            add: "Добавить мероприятие"
        },
        new_category: "Новая категория",
        types: ["Севооборот", "Система обработки почвы", "Система удобрений", "Система семеноводства", "Борьба с сорняками", "Защита от вредителейи болезней", "Борьба с почвенной эрозией"]
    },
    analytics: {
        title: "Аналитика",
        rating: "Рейтинг клетки",
        num_rating: "Номер в рейтинге",
        avg_value: "среднее значение клетки",
        by_crop: "Аналитика по культуре:",
        date: "Дата начала сезона",
        compare_seasons: "Сравнить сезоны",
        cur_season: "Текущий сезон: ",
        no_season: "Сезон не выбран",
        graphs: ["Сумма активных температур и осадков", "Сумма активных температур", "Сумма накопленных осадков"]
    },
    delete: "Удалить клетку",
    delete_field: {
        title: "Удаление субполя",
        text: "Вы действительно хотите удалить субполе?"
    },
    crop_rotation: {
        title: "Севооборот",
        data: "Данные",
        spring: {
            title: "Весенняя обработка",
            choose: "Выберите ИЛИ напишите весеннюю обработку"
        },
        fall: {
            title: "Осенняя обработка",
            choose: "Выберите ИЛИ напишите осеннюю обработку"
        }
    }
}