export default {
    title: "Шаруашылық",
    farms: "Шаруашылықтар",
    current: "Ағымдағы шаруашылық",
    list: {
        title: "Шаруашылықтар тізімі",
        empty: "Шаруашылықтардың тізімі бос"
    },
    empty: {
        header: "Шаруашылықтар әлі жоқ",
        text: "Олар бойынша деректерді талдау үшін шаруашылық құрыңыз"
    },
    edit: {
        title: "Шаруашылықты редакциялау",
        name: "Шаруашылықтың атауы",
        season_not_chosen: "{msg} ұяшықтары үшін маусым таңдалмайды",
        cadastral_add: {
            title: "Кадастрды қосу",
            cadastral_num: "Кадастр нөмірі",
            fields_num: "Ұяшықтар саны",
            add: "Кадастрды қосу",
            upload: "Файлды жүктеу",
            farm_name_placeholder: "Атын енгізіңіз",
            cadastre_input_placeholder: "Кадастр нөмірін енгізіңіз",
            no_info: "Деректер жоқ",
            hectare: "га"
        },
        field: {
            edit: "Ұяшықтарды өңдеу",
            add: "Ұяшық қосу",
            choose: "Ұяшықты таңдаңыз"
        }
    },
    new: {
        title: "Жаңа шаруашылық",
        by_cadastre: {
            title: "Кадастр бойынша",
            cadastres: "Кадастрлар"
        },
        by_map: {
            title: "Карта бойынша",
            new: "Жаңа жер",
            found: "Табылған {msg} кадастрлар",
            instruction: "Дөңгелектелген аймақты басу арқылы оң жақтағы өрістегі кадастрларды таңдаңыз",
            continue_adding: "Келесі қадам"
        },
        regions: "Аймақтар"
    },
    location: "Орналасуы",
    area: "Алаңы",
    data: "{msg} мезгіліне деректер",
    data_for: "Деректер ",
    problem_zones: "Проблемалық аймақтар",
    conclusion: "Қорытынды",
    total_conclusion: "Жалпы қорытынды",
    fields: {
        title: "Ұяшықтар",
        all: "Барлық ұяшықтар",
        about: "Ұяшық туралы ақпарат",
        reproduction: "Репродукция"
    },
    rating: {
        title: "Рейтинг",
        rating_for: "Рейтингі"
    },
    season: {
        title: "Маусымды таңдау",
        start: "Маусымның басталу күні",
        end: "Маусымның аяқталу күні",
        apply: "Ұяшыққа жағыңыз",
        by_fields: "ұяшықтар бойынша",
        by_crops: "дақыл бойынша"
    },
    subscription: {
        title: "Жазылым | (1 га = {msg})",
        amount: "Сома",
        total: "Ақы төлеу: ",
        payment: {
            title: "Төлем әдісі",
            amount: "Төлем шоты",
            card: {
                title: "Картамен төлеу",
                description: "PayBox арқылы"
            },
            invoice: {
                title: "Төлемге шот ұсыну",
                description: "Кез келген банктердің бөлімшесінің кассасы арқылы"
            }
        },
        info: {
            title: "Жазылым дегеніміз не?",
            text: "Egistic Limited жазылымын рәсімдеу арқылы сіз бір егіс маусымында келесі қызметтерді аласыз| (бір жылға) ",
            services: [
                "Өнімділікті болжау қызметі",
                "Проблемалық аймақтарды анықтау қызметі",
                "Арамшөптер мен дақылдарды жіктеу қызметі",
                "Пісу сатысын бекіту мүмкіндігімен вегетациялық қисықты визуализациялау қызметі",
                "Жауын-шашын мен белсенді температураны есептеу қызметі",
                "Негізгі параметрлер бойынша рейтингі бар өрістерді сүзу қызметі",
                "Офлайн карталармен жұмыс істеу мүмкіндіктері сервисі"
            ]
        }
    },
    telematics: {
        title: "Телематика",
        broadcast: {
            title: "Трансляция",
            watch: "Бақылау",
            history: "Тарих",
            width: "Қамту ені",
            speed: "Жылдамдық",
            remain_fuel: "Отын қалдығы"
        },
        events: {
            title: "Іс-шаралар",
            plan: "Гектар өңдеу жоспары",
            fact: "Өңделген аймақтың нақты фактісі",
            consumption: "Отын шығыны",
            date: "Соңғы деректерді алу күні",
            fuel: "Отын деңгейі",
            coordinates: "Координаттары",
            num_machines: "Агрегаттар саны",
            status: "Іс-шараның мәртебесі",
            operational: "Жедел іс-шаралар",
            device: {
                title: "Техника",
                category: "Техниканың категориясы",
                min_speed: "MIN жылдамдық(км/сағ)",
                max_speed: "MAX жылдамдық(км/сағ)"
            },
            agregate: {
                title: "Агрегат",
                category: "Агрегата категориясы"
            },
            treatment_depth: "Өңдеу тереңдігі(см)"
        },
        play: "Ойнату",
        pause: "Іркіліс",
        goto_field: "Ұяшыққа өту",
        timeline: "Таймлайнды орнату",
        overspeed: "Жылдамдықты арттыру",
        average_speed: "Орташа жылдамдық",
        stop: "Техниканы тоқтату",
        stop_reason: "Тоқтату себебі",
        by_machine: {
            title: "агрегат бойынша",
            on_base: "Базасында"
        },
        by_field: {
            title: "ұяшық бойынша"
        },
        filter: {
            title: "Сүзгіш",
            all: "Барлық",
            online: "Онлайн",
            offline: "Оффлайн",
            within: "Базасында",
            outside: "Геозонның артында",
            for_day: "Күндік",
            for_week: "Апталық"
        },
        agregate: {
            title: "Техника"
        }
    },
    agrochem_analys: {
        title: ["Топырақты агрохимиялық талдау", "Агрохимиялық талдау"],
        probe: "1  сынама - {msg} га",
        report: "Агрохимиялық талдау бойынша картограмма",
        upload_results: "Нәтижелерді жүктеу",
        upload_excel_msg: "Для загрузки результатов агрохим анализа загрузите файл в формате Excel (.xls) для клекти, следуя образцу.",
        download_ex: "Үлгіні жүктеу",
        latitude: "Ендік",
        longitude: "Бойлық",
        empty: {
            heading: "Әлі нәтиже жоқ",
            description: "Жаңа агрохимиялық талдауға тапсырыс беріңіз"
        },
        non_empty: {
            company: "Компанияның атауы:",
            price: "Гектарына бағасы:",
            website: "Сайт:",
            contacts: "Байланыстар:"
        },
        get: {
            area: "Сынама алу алаңы: | Алаң:",
            sample_points: "Сынамаға арналған барлық нүктелер:",
            price: "Гектарына бағасы:",
            total: "Сомасы:",
            number: "Байланыс үшін телефон нөмірі",
            success: "Сіздің тапсырысыңыз сәтті орындалды | Шаруашылық үшін топырақты агрохимиялық талдауға тапсырыс сәтті орындалды. Біздің серіктестерімізден қоңырау күтіңіз."
        },
        acidity: {
            title: "Қышқылдық дәрежесі, рН",
            indicators: ["өте қатты қышқыл", "қатты қышқыл", "орташа қышқыл", "сәл қышқыл", "бейтарап", "әлсізсілтілі", "ортасілтісі", "қатты сілтілі", "өте қатты сілтілі"]
        },
        phosphorus: {
            name: "Фосфор",
            title: "Жылжымалы фосфордың құрамы, мг/кг",
            indicators: ["өте төмен", "төмен", "орташа", "жоғары", "асқар", "өте жоғары"]
        },
        potassium: {
            name: "Калий",
            title: "Жылжымалы калийдің құрамы, мг/кг",
            indicators: ["өте төмен", "төмен", "орташа", "жоғары", "асқар", "өте жоғары"]
        },
        nitrogen: {
            name: "Азот",
            title: "Жеңілыдырайтын азот маңызы, мг/кг",
            indicators: ["өте төмен", "төмен", "орташа", "жоғары", "асқар", "өте жоғары"]
        },
        sulfur: {
            name: "Күкірт",
            title: "Күкірт құрамы, мг/кг",
            indicators: ["өте төмен", "төмен", "орташа", "жоғары", "асқар", "өте жоғары"]
        },
        humus: {
            name: "Көң",
            title: "Қарашіріктің мазмұны, %",
            indicators: ["өте төмен", "төмен", "орташа", "жоғары", "асқар", "өте жоғары"]
        }

    },
    delete: "Шаруашылықты жою",
    delete_title: "Жерді алып тастау",
    divide: "Жерді бөлу",
    settings: {
        title: "Баптау",
        change_borders: "Шектерді өзгерту",
        divide: "Ұяшықтарға бөлу",
        clear: "Тазалау"
    }
}