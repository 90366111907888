import {
    Action as AuthActions,
    Mutation as AuthMutaions,
    Getter as AuthGetter
} from './modules/auth/types'

import {
    Action as GeneralActions,
    Mutation as GeneralMutaions,
    Getter as GeneralGetter
} from './modules/general/types'

export const ActionNames = {
    ...AuthActions,
    ...GeneralActions
}

export const MutationNames = {
    ...AuthMutaions,
    ...GeneralMutaions
}

export const GetterNames = {
    ...AuthGetter,
    ...GeneralGetter
}

