export default {
    title: "Farm",
    farms: "Farms",
    current: "Current farm",
    list: {
        title: "Farms list",
        empty: "Farms list is empty"
    },
    empty: {
        header: "There are no farms yet",
        text: "Create a farm to analyze data for them"
    },
    edit: {
        title: "Edit farm",
        name: "Name of the farm",
        season_not_chosen: "for fields {msg} season is not selected",
        cadastral_add: {
            title: "Add cadastre",
            cadastral_num: "Cadastral number",
            fields_num: "Number of fields",
            add: "Add cadastre",
            upload: "Upload file",
            farm_name_placeholder: "Enter name",
            cadastre_input_placeholder: "Enter cadastre number",
            no_info: "No information",
            hectare: "ha"
        },
        field: {
            edit: "Edit fields",
            add: "Add a field",
            choose: "Choose a field"
        }
    },
    new: {
        title: "New farm",
        by_cadastre: {
            title: "By cadastre",
            cadastres: "Cadastres"
        },
        by_map: {
            title: "By map",
            new: "New field",
            found: "Found {msg} cadastres",
            instruction: "Select your cadastres in the field on the right by clicking on the circled area",
            continue_adding: "Next step"
        },
        regions: "Regions"
    },
    location: "Location",
    area: "Area",
    data: "Data for {msg}",
    data_for: "Data for",
    problem_zones: "Problem zones",
    conclusion: "Conclusion",
    total_conclusion: "Overall conclusion",
    fields: {
        title: "Fields",
        all: "All fields",
        about: "Information about the field",
        reproduction: "Reproduction"
    },
    rating: {
        title: "Rating",
        rating_for: "Rating for"
    },
    season: {
        title: "Choose the season",
        start: "Season start date",
        end: "Season end date",
        apply: "Apply in the field",
        by_fields: "by fields",
        by_crops: "by crops"
    },
    subscription: {
        title: "Subscription | (1 he = {msg} )",
        amount: "Amount",
        total: "In total ",
        payment: {
            title: "Payment method",
            amount: "For payment",
            card: {
                title: "Pay by card",
                description: "Through paybox"
            },
            invoice: {
                title: "Invoice the payment",
                description: "Through the cash desk of any bank account"
            }
        },
        info: {
            title: "What does mean 'subscription'?",
            text: "By subscribing to Egistic Limited, you will receive the following services for one sowin season | (for one year)",
            services: [
                "Productivity prediction service",
                "Problem zone prediction service",
                "Weed and crop classification service",
                "Vegetation curve visualization service with the possibility of fixing the maturation stage",
                "Service for calculating precipitation and active temperatures",
                "Field filtering service with its rating by main parameters",
                "Opportunity to work with offline maps"
            ]
        }
    },
    telematics: {
        title: "Telematics",
        broadcast: {
            title: "Broadcast",
            watch: "Watch",
            history: "History",
            width: "Coverage width",
            speed: "Speed",
            remain_fuel: "Remaining fuel"
        },
        events: {
            title: "Events",
            plan: "Plan for processing hectares",
            fact: "Actual fact of the processed area",
            consumption: "Fuel consumption",
            date: "Date when the last data was received",
            fuel: "Fuel level",
            coordinates: "Coordinates",
            num_machines: "Amount of machines",
            status: "The status of event",
            operational: "Operational activities",
            device: {
                title: "Machine",
                category: "Machine category",
                min_speed: "MIN speed(km/h)",
                max_speed: "MAX speed(km/h)"
            },
            agregate: {
                title: "Agregate",
                category: "Agregate category"
            },
            treatment_depth: "Treatment depth(cm)"
        },
        play: "Play",
        pause: "Pause",
        goto_field: "Go to the field",
        timeline: "Set up a timeline",
        overspeed: "Overspeed",
        average_speed: "Average speed",
        stop: "Equipment stop",
        stop_reason: "Reason for a stop",
        by_machine: {
            title: "by machine",
            on_base: "On the basis"
        },
        by_field: {
            title: "by field"
        },
        filter: {
            title: "Filter",
            all: "All",
            online: "Online",
            offline: "Offline",
            within: "Within the base",
            outside: "Outside of the geozone",
            for_day: "For a day",
            for_week: "For a week"
        },
        agregate: {
            title: "Aggregate"
        }
    },
    agrochem_analys: {
        title: ["Soil agrochemical analysis", "Agrochemical analysis"],
        probe: "1 sample - {msg} he",
        report: "Agrochemical analyze cartogram",
        upload_results: "Upload results",
        upload_excel_msg: "Для загрузки результатов агрохим анализа загрузите файл в формате Excel (.xls) для клекти, следуя образцу.",
        download_ex: "Download a sample",
        latitude: "Latitude",
        longitude: "Longitude",
        empty: {
            heading: "No results",
            description: "Order new agrochemical analysis"
        },
        non_empty: {
            company: "Company name:",
            price: "Price per hectare:",
            website: "Website:",
            contacts: "Conracts:"
        },
        get: {
            area: "Sampling area",
            sample_points: "All sample points:",
            price: "Price per hectare:",
            total: "Total:",
            number: "Your mobile number",
            success: "Your order has been successfully completed | The order for agricultural soil analysis on the farm has been successfully completed. Wait for a call from our partners."
        },
        acidity: {
            title: "Acidity, pH aq.",
            indicators: ["very strong acidic", "strong acidic", "medium acidic", "weak acidic", "neutral", "weak alkaline", "medium alkaline", "strong alkaline", "very strong alkaline"]
        },
        phosphorus: {
            name: "Phosphorus",
            title: "Mobile phosphorus content, mg/kg",
            indicators: ["very low", "low", "medium", "high", "high", "very high"]
        },
        potassium: {
            name: "Potassium",
            title: "Mobile potassium content, mg/kg",
            indicators: ["very low", "low", "medium", "high", "high", "very high"]
        },
        nitrogen: {
            name: "Nitrogen",
            title: "Content of easily hydrolyzable nitrogen, mg/kg",
            indicators: ["very low", "low", "medium", "high", "high", "very high"]
        },
        sulfur: {
            name: "Sulfur",
            title: "Sulfur content, mg/kg",
            indicators: ["very low", "low", "medium", "high", "high", "very high"]
        },
        humus: {
            name: "Humus",
            title: "Humus content, %",
            indicators: ["very low", "low", "medium", "high", "high", "very high"]
        }

    },
    delete: "Delete farm",
    delete_title: "Deleting a field",
    divide: "Divide the field",
    settings: {
        title: "Settings",
        change_borders: "Change the borders",
        divide: "Divide into fields",
        clear: "Clear"
    }
}