export default {
    title: "Справочник",
    search: "Поиск по названию..",
    sort: {
        sort_by: "Сортировать по:",
        a_z: "А-Я",
        popular: "Популярные",
        watched: "Просматриваемые"
    },
    category: ["Все", "Картофель", "Озимый рапс", "Рапс яровой", "Сахарная свекла", "Томаты", "Лен масличный", "Соя", "Кукуруза", "Подсолнечник", "Озимая пшеница", "Яровая пшеница", "Яровой ячмень", "Озимый ячмень", "Виноградники", "Яблоня", "Лук", "Огруцы", "Рис"],
    preview: {
        farm: {
            title: "Знакомство с хозяйством",
            detail: "Перейдите к хозяйству чтобы начать знакомство"
        },
        cell: {
            title: "Знакомство с клеткой",
            detail: "Перейдите к клетку чтобы начать знакомство"
        },
        stats: {
            title: "Знакомство с показателями",
            detail: "Перейдите к показателям чтобы начать знакомство"
        },
        no_data: "У вас пока нету обработанных заказов"
    }
}