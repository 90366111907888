export default {
    title: "Profile",
    farms_info: "Information about farms",
    templates: {
        my_title: "My templates",
        no_templates: "You do not have templates yet",
        new_sugesstion: "Create new templates for a fast usage in you tech maps",
        new_button: "Create a new template",
        name: "Title",
        name_input: "Type template title"
    },
    edit: {
        title: "Edit my profile",
        profile_editing: {
            title: "Editing a profile",
            changed: "Data is changed",
            success: "Profile data was changed successfully"
        }
    },
    subscriptions: {
        title: ["My subscriptions", "My orders", "Orders"],
        agrochem: "Agrochemical analysis of soil: Farm",
        ready: "The results are in",
        sent: "Request has been sent",
        paid: "Paid",
        not_paid: "Not paid"
    },
    advertisement: {
        welcome: "Welcome to Egistic Limited!",
        finishes_after: "The promotional period will end in:",
        days: " days ",
        hours: " hours ",
        minutes: " minutes ",
        seconds: " seconds",
        free_text: [
            "/En/Поздравляем! Вы активировали бесплатную подписку на 14 дней.",
            "На период бесплатного пользования Вам будут доступны:",
            "・ Цифровой журнал агронома с возможностью создания технологической карты",
            "・ Анализ поля по космическим снимкам (уровень вегетации, проблемные зоны, засоренность поля, прогноз урожайности)",
            "・ Модуль телеметики техники по GPS трекам",
            "・ Модуль агроскаутинга",
            "Вы также можете оформить годовую подписку в разделе \"Хозяйства\"."
        ]
    },
    staff: {
        title: "My staff",
        n_employee: "One employee | {n} employees",
        add_title: "Add an employee",
        name: "Employee fullname",
        access: "Employee access type: ",
        open_access: "Open access to",
        full: "Full",
        restricted: "Limited",
        surname: "Employee's surname",
        fullname: "Employee's full name",
        email: "Email",
        number: "Phone number",
        notification: "Notification type",
        assign_manager: "Assign as a manager"
    },
    service_book: {
        title: "Service book",
        details: "Details",
        record_adding: {
            title: "record"
        }
    },
    storage: {
        title: "Storage",
        storage_search: "Search in storage",
        storage_remains: "Remains in storage",
        operation: "operation"
    },
    password_change: {
        title: "Change the password",
        password_changing: {
            current_password: "Current password",
            new_password: "New password"
        }
    },
    farms: {
        table: {
            cadastre_number: "Cadastre number",
            area: "Area",
            fields: "Fields",
            arable_land: "Arable lands, he",
            pasture: "Pasture, he",
            haymaking: "Haymaking, he",
            others: "Others, he",
            registration_date: "registration date",
            registration_number: "Registration №",
            agreement_date: "Contract terms"
        }
    }
}