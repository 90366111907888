

















































































import { Component, Vue } from 'vue-property-decorator'
import { apiUrl, openCrossOriginWebSite } from '@/domain/constants'
import { httpGet } from '@/util/http'
import i18n from '@/i18n'

@Component
export default class PasswordReset extends Vue {
    private sendingRequest = false
    private email = ''
    private errorText = ''
    private showCheckEmailModal = false

    private resetPassword() {
        if (this.sendingRequest) return
        this.sendingRequest = true
        httpGet({
            url: `${apiUrl}/recovery_request/${this.email}`,
            isSecureRequest: true,
            onSuccess: json => {
                if (json === 'Success') {
                    this.showCheckEmailModal = true
                } else if (json.email && json.email.length > 0) {
                    if (json.email[0] === 'invalid_format') {
                        this.errorText = i18n.t('headers.auth.signin.errors.email') as string
                    } else if (json.email[0] === 'user_not_found') {
                        this.errorText = i18n.t('headers.auth.signin.errors.not_found') as string
                    } else this.errorText = i18n.t('headers.auth.signin.errors.server') as string
                } else {
                    this.errorText = i18n.t('headers.auth.signin.errors.server') as string
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.sendingRequest = false
            }
        })
    }

    private resetAll() {
        this.sendingRequest = false
        this.email = ''
        this.errorText = ''
        this.showCheckEmailModal = false
        const route: any = {
            name: 'signin'
        }
        if (this.$route.params.redirect) this.$route.params.redirect = this.$route.params.redirect
        this.$router.push(route).catch(err => {/**/})
    }

    private openLandingPage() {
        openCrossOriginWebSite('landing')
    }
}
