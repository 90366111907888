






















































































import { Component, Vue, Watch } from 'vue-property-decorator'
import {apiUrl, COOKIE_KEY_AUTH_HEADER, openCrossOriginWebSite} from '@/domain/constants'
import { httpPost, httpGet } from '@/util/http'
import { Action, Mutation, Getter } from 'vuex-class'
import { GetterNames, MutationNames, ActionNames } from '@/store/types'
import VuePhoneNumberInput from "vue-phone-number-input"
@Component({
    components: {
        VuePhoneNumberInput
    }
})
export default class FoundedNotVerified extends Vue {
    @Getter(GetterNames.getSignViewData) private getSignViewData !: any

    private isLogining = false
    private passwordFormError = ''
    private newUsernameVModal: any = ''
    private newUsername: any = ''

    private mounted() {
        Vue.alert(`Укажите номер для отправки SMS кода. В дальнейшем вход будет через указанный номер`, `Пройдите верификацию`, 'success')
    }

    private updatePhoneNumber(data: any) {
        this.newUsername = data.formattedNumber
    }

    private signButton() {
        console.log('here')
        localStorage.setItem('email', this.newUsername)
        localStorage.setItem('type', '3')
        this.$router.push({ name: 'sms-code' })
    }

    private openLandingPage() {
        openCrossOriginWebSite('landing')
    }

    private openRoute() {
        this.$router.push({name: 'signin'})
    }
}
