
























































































import { Component, Vue } from 'vue-property-decorator'
import { apiUrl, openCrossOriginWebSite } from '@/domain/constants'
import { httpPost } from '@/util/http'
import i18n from '@/i18n'

@Component
export default class PasswordRecovery extends Vue {
    private newPassword1 = ''
    private newPassword2 = ''
    private showPassword1 = false
    private showPassword2 = false
    private errorText = ''
    private isPasswordChanging = false
    private isPasswordChangedSuccessfully = false

    private saveNewPassword() {
        if (!this.isPasswordCorrect() || this.isPasswordChanging) return
        this.isPasswordChangedSuccessfully = false
        this.isPasswordChanging = true
        httpPost({
            url: `${apiUrl}/recovery_request/${this.$route.query.id}/${this.$route.query.token}/`,
            body: {
                uidb64: this.$route.query.id,
                token: this.$route.query.token,
                new_password: this.newPassword1
            },
            onSuccess: json => {
                if (json !== 'Success') {
                    this.errorText = i18n.t('headers.auth.signin.errors.data') as string
                } else {
                    this.newPassword1 = ''
                    this.newPassword2 = ''
                    this.isPasswordChangedSuccessfully = true
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.isPasswordChanging = false
            }
        })
    }

    private goToMain() {
        this.$router.push({name: 'signin'}).catch(err => {/* */})
    }

    private isPasswordCorrect() {
        if (this.newPassword1.length >= 6) {
            if (this.newPassword1 === this.newPassword2) {
                this.errorText = ''
                return true
            } else {
                this.errorText = i18n.t('messages.errors.passwords_dont_match') as string
                return false
            }
        } else {
            this.errorText = i18n.t('messages.errors.password_six') as string
            return false
        }
    }

    private openLandingPage() {
        openCrossOriginWebSite('landing')
    }
}
