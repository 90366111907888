export default {
    title: "Fields",
    field: "Field",
    text: "Каждое поле уникальное. После указания культуры и сроков посева в данном разделе вы получите максимум информации о состоянии Вашего поля.",
    info: {
        crops: "Cultivated crop",
        name_farmer: "Agronomist's name",
        num: "Number",
        area: "Area",
        season: "Season",
        date: "Date of sowing",
        vegetation: "Vegetation period",
        data: "Data for",
        crop: "Growth",
        sort: "Sort",
        reproduction: "Reproduction",
        date_cleaning: "Cleaning date",
        settings: "Access options"
    },
    edit: {
        title: "Editing the passport",
        name: "Agronomist's full name | First name",
        area: "Area, he"
    },
    history: {
        title: "Field history",
        predecessor: "Predecessor:",
        yield: "Yield:",
        steam: "Fallow land",
        methods: "Processing method",
        year: "Year",
        edit: "Edit a history",
        add: "Add a history"
    },
    indicators: {
        title: "Indicators",
        indicator: "Indicator",
        phosphorus: "Phosphorus",
        potassium: "Potassium",
        nitrogen: "Nitrogen",
        sulfur: "Sulfur",
        humus: "Humus",
        info: {
            notes: "By comparing past images, our system detected problem areas. We recommend writing it down in a note.",
            empty: "You don't have any processed orders yet"
        },
        data: {
            title: "Data",
            data_from: "Data from",
            types: ["Comparison of data chronicle", "Problem area", "Level of contamination"]
        },
        indicators: {
            vegetation: {
                title: "Vegetation",
                text: "The «biomass assessment map» or «vegetation index» allows to assess the state of plants in both dense vegetation cover and sparse vegetation conditions. the normalized relative vegetation index (NDVI) was used to perform this task. this index is one of the most common and used indices for quantifying vegetation cover."
            },
            nitrogen: {
                title: "Nitrogen",
                text: "«Map of nitrogen content in the leaves of plants» allows you to detect problem areas of the state of crops in within the boundaries of the cadastral field, as well as as a source parameter in the models of crop yield"
            },
            humidity: {
                title: "Humidity",
                text: "The «moisture content map» or «soil surface moisture index» allows you to assess the heterogeneity of the degree of moisture of vegetation and soil. This index is more sensitive to the moisture content in the soil and leaves of plants. To perform this task, the soil and vegetation humidity index was used. Normalized difference water index is an indicator of the moisture content in the soil and leaves of plants."
            },
            chlorophyll: {
                title: "Chlorophyll",
                text: "«The map of chlorophyll content in plants » or «vegetation health index» allows you to detect problem areas of crop condition within the boundaries of the cadastral field, as well as as a starting parameter in forecasting crop yields. The level of chlorophyll indicates the photosynthetic phase in which the plant is located, which in turn indicates the maturity of the plant."
            }
        },
        fertilization: {
            title: "Fertilization",
            map: "Map for applying nitrogen fertilizers",
            vegetation_zone: "area of vegetation",
            avg_value: "average value",
            rate: "application rate",
            total: "Total",
            mass: "kg of fertilizer"
        },
        report: {
            title: "Report",
            problem_zones: "Report for problem zones",
            weediness: "Report for contamination levels",
            pz_n_w: "Report for problem zones and contamination levels"
        }
    },
    chronicle: {
        title: "Chronicle",
        by_orders: "By orders",
        by_weather: "By weather",
        not_show: "Not show"
    },
    yield: {
        title: "Yield",
        type: "Enter the yield",
        forecast: "Yield forecast"
    },
    documents: {
        title: "Documents",
        add: "Add a document"
    },
    notes: {
        title: "Notes",
        empty: "Click on the map to create a note",
        date: "Date",
        time: "Time",
        note_addition: {
            title: "Adding a note",
            problem: {
                title: "Discovered problem",
                type1: "Disease",
                type2: "Pest",
                type3: "Weed",
                type4: "Other"
            },
            note: {
                title: "Text of the note",
                text: "Start writing...",
                notice: "Mention an employee",
                search: "Search for an employee",
                table_note: "Примечание (скорость ветра, облачность, температура, тип опрыскивателя и т.д.)"
            },
            problem_details: {
                type1: {
                    title: "Disease",
                    epv: "ЭПВ, фаза",
                    indicator: "Growth, (%)",
                    input1: {
                        placeholder: "Choose a disease"
                    },
                    input2: {
                        header: "Growth, %",
                        placeholder: "Enter the growth percentage"
                    },
                    advice: {
                        header: "Fungicide",
                        placeholder: "Choose the fungicide"
                    },
                    add: "Add a disease",
                    add_prep: "Add a fungicide"
                },
                type2: {
                    title: "Pest type",
                    epv: "ЭПВ, number/m²",
                    indicator: "Occupation, (number/m²)",
                    input1: {
                        placeholder: "Choose a pest type"
                    },
                    input2: {
                        header: "Occupation, number/m²",
                        placeholder: "Enter an indicator"
                    },
                    advice: {
                        header: "Insecticide",
                        placeholder: "Choose the insecticide"
                    },
                    add: "Add a pest type",
                    add_prep: "Add a insecticide"
                },
                type3: {
                    title: "Weed type",
                    epv: "ЭПВ, number/m²",
                    indicator: "Contamination degree (Х)",
                    input1: {
                        placeholder: "Choose a weed type"
                    },
                    input2: {
                        header: "Contamination degree",
                        placeholder: "Enter the contamination degree"
                    },
                    input3: {
                        header: "Amount",
                        placeholder: "Enter amount"
                    },
                    advice: {
                        header: "Herbicides",
                        placeholder: "Choose the herbicide"
                    },
                    add: "Add a weed type",
                    add_prep: "Add a herbicide"
                },
                advice_title: "Treatment tips"
            },
            treatment_date: "Treatment date",
            treatment_method: "Treatment method",
            photo: "Photos",
            photo_info: "Information about photo",
            added_by: "Added by",
            added_from: "Added from",
            from_pc: "PC",
            from_phone: "Mobile phone",
            date: "Detour date",
            phen_stage: "Phenological stage",
            send: "Send a notification",
            create: "Create an event based on a note"
        },
        note_details: {
            title: "Note",
            consumption_rate: "Consumption rate",
            processing_method: "Processing method",
            target: "Target ",
            creation_date: "Created on"
        },
        reply: {
            title: "Response to the note",
            text: "Response text",
            from: "Reply from"
        },
        note_editing: "Editing a note"
    },
    tasks: {
        title: "Tasks",
        apply: "Apply to field",
        without_crop: "Without crop",
        event: "Event",
        event_dates: "Event dates",
        field_num: "The task of the field #",
        event_addition: {
            title: "New event",
            general: {
                title: "Main categories",
                name: "Event name",
                date: "Date of the event",
                cost: "Cost of the event"
            },
            additional: {
                title: "Additional categories",
                machine: "Machine",
                speed_limit: "Speed limit",
                staff: "Staff",
                output: "Production",
                fuel: "Fuel",
                name: "Name",
                amount: "amount",
                unit: "unit",
                send: "Send to the telematics:"
            }
        },
        event_change: {
            edit: "Edit the event",
            new: "New event",
            update: "Update the event",
            add: "Add an event"
        },
        new_category: "New category",
        types: ["Crop rotation", "Tillage system", "Fertilizer system", "Seed production system", "Weed control", "Pest and disease control", "Soil erosion control"]
    },
    analytics: {
        title: "Analytics",
        rating: "The rating of field",
        num_rating: "Number in the rating",
        avg_value: "the average value of field",
        by_crop: "Analytics by crop:",
        date: "Start date of the season",
        compare_seasons: "Compare seasons",
        cur_season: "Current season: ",
        no_season: "Season not chosen",
        graphs: ["Sum of active temperatures and precipitation", "Sum of active temperatures", "Sum of accumulated precipitation"]
    },
    delete: "Delete the field",
    delete_field: {
        title: "Deleting a field",
        text: "Do you really want to delete a field?"
    },
    crop_rotation: {
        title: "Crop rotation",
        data: "Data",
        spring: {
            title: "Spring crop rotation",
            choose: "Select oor type spring crop rotation"
        },
        fall: {
            title: "Fall crop rotation",
            choose: "Select oor type fall crop rotation"
        }
    }
}