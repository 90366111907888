export default {
    title: "Профиль",
    farms_info: "Информация о хозяйствах",
    templates: {
        my_title: "Мои шаблоны",
        no_templates: "Ещё нет шаблонов",
        new_sugesstion: "Создайте шаблоны для быстрого применения в технологических картах",
        new_button: "Создать шаблон",
        name: "Название",
        name_input: "Введите название шаблона"
    },
    edit: {
        title: "Редактировать профиль",
        profile_editing: {
            title: "Редактирование профиля",
            changed: "Данные изменены",
            success: "Данные профиля успешно изменены"
        }
    },
    subscriptions: {
        title: ["Мои подписки", "Мои заказы", "Заказы"],
        agrochem: "Агрохим анализ почвы: Хозяйство",
        ready: "Результаты готовы",
        sent: "Заявка отправлена",
        paid: "Оплачено",
        not_paid: "Не оплачено"
    },
    advertisement: {
        welcome: "Приветствуем Вас в Egistic Limited!",
        finishes_after: "Акционный период закончится через:",
        days: " дней ",
        hours: " часов ",
        minutes: " минут ",
        seconds: " секунд",
        free_text: [
            "Поздравляем! Вы активировали бесплатную подписку на 14 дней.",
            "На период бесплатного пользования Вам будут доступны:",
            "・ Цифровой журнал агронома с возможностью создания технологической карты",
            "・ Анализ поля по космическим снимкам (уровень вегетации, проблемные зоны, засоренность поля, прогноз урожайности)",
            "・ Модуль телеметики техники по GPS трекам",
            "・ Модуль агроскаутинга",
            "Вы также можете оформить годовую подписку в разделе \"Хозяйства\"."
        ]
    },
    staff: {
        title: "Сотрудники",
        n_employee: "Сотрудников не выбрано | Один сотрудник | {n} сотрудника | {n} сотрудников",
        add_title: "Добавить сотрудника",
        name: "Имя сотрудника",
        access: "Тип доступа сотрудника: ",
        open_access: "Открыть доступ к",
        full: "Полный",
        restricted: "Ограниченный",
        surname: "Фамилия сотрудника",
        fullname: "ФИО сотрудника",
        email: "Email",
        number: "Номер телефона",
        notification: "Тип уведомления",
        assign_manager: "Назначить директором"
    },
    service_book: {
        title: "Сервисная книга",
        details: "Подробнее",
        record_adding: {
            title: "запись"
        }
    },
    storage: {
        title: "Склад",
        storage_search: "Поиск по складу",
        storage_remains: "Остатки по складу",
        operation: "операцию"
    },
    password_change: {
        title: "Смена пароля",
        password_changing: {
            current_password: "Текущий пароль",
            new_password: "Новый пароль"
        }
    },
    farms: {
        table: {
            cadastre_number: "Кадастровый номер",
            area: "Площадь",
            fields: "Клетки",
            arable_land: "Пашня, га",
            pasture: "Пастбище, га",
            haymaking: "Сенокос, га",
            others: "Прочие, га",
            registration_date: "Дата регистрации",
            registration_number: "№ договора",
            agreement_date: "Срок договора"
        }
    }
}