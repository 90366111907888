





import { Component, Vue } from 'vue-property-decorator'
import ContactModel from './ContactModel/Index.vue'
@Component({
    components: {
        ContactModel
    }
})
export default class FrontComponents extends Vue {
}
