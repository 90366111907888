export default {
    guide: "Guide",
    cell: {
        edit: "Edit the cell",
        add: "Add a cell",
        delete: "Delete the cell",
        combine: "Merge cells",
        split: "Split the cell",
        info: "Information about the cell"
    },
    vertix: {
        delete: "Delete a vertex",
        delete_several: "Delete vertices"
    },
    hole: {
        create: "Create a hole"
    },
    farm: {
        new: "New farm"
    },
    close: "Close",
    back: "Back",
    forward: "Forward",
    learn_more: "Learn more",
    reset: "Reset",
    add: "Add",
    edit: "Edit",
    change: "Change",
    settings: "Settings",
    add_note: "Add a note",
    add_storage: "Add a storage",
    add_operation: "Add an operation",
    delete: "Delete",
    print: "Print",
    exit: "Exit",
    tile: "Tile",
    table: "List",
    search: "Search",
    steps: {
        prev: "Previous",
        next: "Next"
    },
    download: {
        title: "Download",
        excel: "Download an Excel"
    },
    choose: {
        map: "Choose on the map"
    },
    employees: "Employees",
    subscribe: "Subscribe",
    actions: "Actions",
    sort: "Sort",
    broadcasting: "Broadcasting",
    service_book: "Service book",
    manage_notifs: "Manage Notifications"
}