import { getAuthToken } from '@/domain/constants'
import { errorHandler } from './errorHandler'

interface HttpGetArgs {
    url: string,
    isSecureRequest?: boolean,
    detailResponse?: boolean,
    onSuccess: (json: any) => void,
    onError?: (error: any) => void,
    doFinally?: () => void
}

interface HttpPostArgs {
    url: string,
    isSecureRequest?: boolean,
    detailResponse?: boolean,
    body: any,
    onSuccess: (json: any) => void,
    onError?: (error: any) => void,
    doFinally?: () => void
}

export const httpGet = ({
        url,
        onSuccess,
        onError,
        doFinally,
        isSecureRequest = false,
        detailResponse = false
    }: HttpGetArgs) => {
        const headers: any = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        // tslint:disable-next-line:no-string-literal
        if (isSecureRequest) headers['Authorization'] = getAuthToken()
        fetch(url, {
            method: 'GET',
            headers
        }).then(response => {
            errorHandler(response)
            if (detailResponse) {
                return response.json().then(result => {
                    return {
                        json: result,
                        ok: response.ok,
                        status: response.status
                    }
                })
            } else {
                return response.json()
            }
        }).then(json => {
            onSuccess(json)
        }).catch(e => {
            if (onError) onError(e)
        }).finally(() => {
            if (doFinally) doFinally()
        })
    }

export const httpPost = ({
        url,
        body,
        onSuccess,
        onError,
        doFinally,
        isSecureRequest = false,
        detailResponse = false
    }: HttpPostArgs) => {
        const headers: any = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        // tslint:disable-next-line:no-string-literal
        if (isSecureRequest) headers['Authorization'] = getAuthToken()
        const stringifiedJson = JSON.stringify(body)
        fetch(url, {
            method: 'post',
            headers,
            body: stringifiedJson
        }).then(response => {
            errorHandler(response)
            if (detailResponse) {
                return response.json().then(result => {
                    return {
                        json: result,
                        ok: response.ok,
                        status: response.status
                    }
                })
            } else {
                return response.json()
            }
        }).then(json => {
            onSuccess(json)
        }).catch(e => {
            if (onError) onError(e)
        }).finally(() => {
            if (doFinally) doFinally()
        })
    }