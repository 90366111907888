export default {
    title0: "Вход в кабинет",
    title: "Вход в кабинет / Регистрация",
    register: "Либо | зарегистрируйтесь бесплатно",
    number: "Номер телефона или e-mail",
    numberOnly: "Номер телефона",
    remember: "Запомнить меня",
    password_forgotten: "Забыли пароль?",
    email_confirm: "Подтверждение почты",
    password_reset: {
        title: "Восстановление пароля",
        explanation_1: "Укажите email для восстановления пароля",
        notification: "На Вашу почту | отправлена ссылка для подтверждения. Перейдите, пожалуйста, по ней для входа.",
        explanation_2: "Придумайте новый пароль",
        new_password: "Новый пароль",
        password_repeat: "Подтвердите пароль",
        success: "Пароль успешно изменен!",
        message: "Пожалуйста войдите в систему используя новый пароль"
    },
    errors: {
        google: "Проблема с google account, попробуйте еще раз",
        typo: "Неправильно введен логин или пароль",
        email: "Неправильный формат email-a",
        not_found: "Пользователь с данным email-ом не найден",
        server: "Ошибка сервера",
        data: "Неправильно введены данные",
        password: "Пароль должен содержать не менее 6 символов"
    },
    loginSmsSubtitle: "Укажите пароль для входа в аккаунт",
    next: "Продолжить",
    codeOnSms: "Введите код из смс",
    subtitleCodeOnSms: "Мы отправили код подтверждения на номер",
    code: "Введите код",
    passwordSignUpSuccess: "Вы успешно прошли SMS подверждение. Установите пароль",
    passwordSignUpAlready: "Номер уже проходил верификацию SMS. Установите пароль",
    successSignUp: "Вы успешно зарегистрировались",
    successSignIn: "Введите номер для Входа",
    successVerification: "Вы успешно прошли Верификацию",
    confirmPerson: "Подтвердите личность",
    confirmPersonText: "Мы обновляем систему входа и регистрации. Сейчас войти можно через номер телефона, так как Вы зарегистрированы через Email, просим указать Ваш номер телефона",
    confirmPersonWithNumber: "Мы обновляем систему входа и регистрации. Нажмите далее для SMS верификации. В дальнейшем вход на сайт будет через указанный номер",
    confirmPersonWithoutNumber: "Мы обновляем систему входа и регистрации. Укажите номер телефона для SMS верификации. В дальнейшем вход на сайт будет через указаный номер",
    confirmPersonChangeUsername: "Мы обновляем систему входа и регистрации. Укажите SMS код, который был отправлен на номер"
}