













































































import { Vue, Component, Watch } from "vue-property-decorator"
import {Getter} from "vuex-class"
import {GetterNames} from "@/store/types"
import {apiUrl, openCrossOriginWebSite} from "@/domain/constants"
import {httpPost} from "@/util/http"
import i18n from "@/i18n"
@Component
export default class SmsCode extends Vue {
    @Getter(GetterNames.getSignViewData) private getSignViewData !: any
    private isLogining = false
    private passwordFormError = ''

    private numberPhone: any = ''
    private email: any = ''

    private type: any = null
    private code: any = ''

    private minutes: any = null
    private seconds: any = null
    private time: any = 180
    private timer: any = null

    private mounted() {
        this.numberPhone = localStorage.getItem('username') // || this.$route.params.number
        this.type = localStorage.getItem('type')
        this.email = localStorage.getItem('email')
        console.log(this.type, 'type aaa')
        if (this.type === '3') {
            Vue.alert(`${this.$t('headers.auth.signin.confirmPersonChangeUsername')}`, `${this.$t('headers.auth.signin.confirmPerson')}`, 'success')
        }
        this.smsVerification(this.numberPhone, this.type)
        // this.smsVerification(this.$route.params.number, this.$route.params.type)
    }

    // sms verification
    private smsVerification(numberr: any, type: any) {
        console.log(numberr, type, 'sms send')
        this.isLogining = true
        httpPost({
            url: `${apiUrl}/phone/verification/send/`,
            detailResponse: true,
            isSecureRequest: true,
            body: {
                phone: numberr,
                type
            },
            onSuccess: json => {
                console.log(json)
                this.time = 180
                this.startTimer()
                if (json.ok) {
                    this.numberPhone = json.json.phone
                    this.type = json.json.type
                }
            },
            onError: error => {
                this.passwordFormError = i18n.t('headers.auth.signin.errors.google') as string
                console.log(error)
            },
            doFinally: () => {
                this.isLogining = false
            }
        })
    }

    private checkForError() {
        let counter = 0
        if (this.code === '') {
            Vue.alert('Введите код', 'Ошибка', 'warning')
            counter++
        }
        return counter === 0
    }

    private checkVerification() {
        console.log(this.code, this.numberPhone, this.type, 'sms check')
        if (!this.checkForError()) return
        this.isLogining = true
        httpPost({
            url: `${apiUrl}/phone/verification/check/`,
            detailResponse: true,
            isSecureRequest: true,
            body: {
                phone: this.numberPhone,
                code: this.code,
                type: this.type
            },
            onSuccess: json => {
                this.passwordFormError = ''
                console.log(json)
                if (json.ok) {
                    if (this.type === '2' || this.type === 2) {
                        this.$router.push({name: 'password-signup', params: {phone: this.numberPhone, exist: 'success', type: '2'}})
                    }
                    if (this.type === '3' || this.type === 3) {
                        console.log(this.email, 'email')
                        console.log(this.numberPhone, 'numberPhone')

                        if (this.email.includes('@')) {
                            this.changeUsername(this.email, this.numberPhone)
                            return
                        }
                        this.changeUsername(this.numberPhone, this.email)
                    }
                    if (json.json.success === 'Phone verified' ) {
                        localStorage.setItem('username', this.numberPhone)
                        localStorage.setItem('type', this.type)
                        this.$router.push({name: 'password-signup', params: {phone: this.numberPhone, exist: 'success'}})
                    } else {
                        this.passwordFormError = `Не правильный SMS код. Попробуйте еще раз повторно отправить SMS.`
                    }
                } else {
                    this.passwordFormError = `Не правильный SMS код. Попробуйте еще раз повторно отправить SMS.`
                }
            },
            onError: error => {
                this.passwordFormError = `Не правильный SMS код. Попробуйте еще раз повторно отправить SMS.`
                console.log(error)
            },
            doFinally: () => {
                this.isLogining = false
            }
        })
    }

    private changeUsername(oldUsername: any, newUsername: any) {
        console.log(oldUsername, 'old')
        console.log(newUsername, 'new')
        this.isLogining = true
        let oldUsernameWithoutDog = ''
        if (oldUsername.includes('@')) oldUsernameWithoutDog = oldUsername.split('@').reverse().splice(-1).join()
        else oldUsernameWithoutDog = oldUsername
        console.log(oldUsernameWithoutDog, newUsername)
        httpPost({
            url: `${apiUrl}/change_username/`,
            detailResponse: true,
            isSecureRequest: true,
            body: {
                old_username: oldUsernameWithoutDog,
                new_username: newUsername
            },
            onSuccess: json => {
                console.log(json)
                localStorage.removeItem('email')
                this.$router.push({name: 'signin', params: { exist: 'onlyPhone' }})
            },
            onError: error => {
                this.passwordFormError = i18n.t('headers.auth.signin.errors.google') as string
                console.log(error)
            },
            doFinally: () => {
                this.isLogining = false
            }
        })
    }

    private openLandingPage() {
        openCrossOriginWebSite('landing')
    }

    private openRoute() {
        this.$router.push({name: 'signin'})
    }

    // timer
    private startTimer() {
        this.timer = setInterval(() => {
            this.minutes = Math.trunc(this.time / 60)
            if (this.minutes < 10) {
                this.minutes = `0${this.minutes}`
            }
            this.seconds = this.time % 60
            if (this.seconds < 10) {
                this.seconds = `0${this.seconds}`
            }
            this.time -= 1
        }, 1000)
        console.log(this.timer)
    }

    private stopTimer() {
        clearTimeout(this.timer)
        this.timer = null
    }

    @Watch('time')
    private watchTimer() {
        if (this.time === 0) {
            this.stopTimer()
        }
    }
}
