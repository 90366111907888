import Vue from 'vue'
import Router from 'vue-router'

import MainContainer from '@/views/Index.vue'
import SignIn from '@/views/auth/SignIn.vue'
import SignUp from '@/views/auth/SignUp.vue'
import PasswordReset from '@/views/auth/PasswordReset.vue'
import PasswordRecovery from '@/views/auth/PasswordRecovery.vue'

// SMS
// import SignInBySms from "@/views/auth/sms/SignUpPhone.vue"
import SmsCode from "@/views/auth/sms/SmsCode.vue"
import PasswordSignUp from "@/views/auth/sms/PasswordSignUp.vue"
import PasswordSignIn from "@/views/auth/PasswordSignIn.vue"
import FoundedNotVerified from "@/views/auth/phoneStatus/FoundedNotVerified.vue"

Vue.use(Router)
const DEFAULT_TITLE = 'EGISTIC - система управления хозяйством для сельхозтоваропроизводителей и консультантов в области сельского хозяйства. Настало время менять систему агробизнеса.'
const router =  new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: MainContainer,
            children: [
                {
                    path: '/signin/:redirect?',
                    name: 'signin',
                    component: SignIn
                },
                // {
                //     path: '/signup/:redirect?',
                //     name: 'signup',
                //     component: SignUp
                // },
                {
                    path: '/password_reset/:redirect?',
                    name: 'password_reset',
                    component: PasswordReset,
                    props: true
                },
                {
                    path: '/password_recovery',
                    name: 'password_recovery',
                    component: PasswordRecovery,
                    props: true
                },
                {
                    path: '/sms-code',
                    name: 'sms-code',
                    component: SmsCode
                },
                {
                    path: '/password-signin',
                    name: 'password-signin',
                    component: PasswordSignIn
                },
                {
                    path: '/password-signup',
                    name: 'password-signup',
                    component: PasswordSignUp
                },
                {
                    path: 'verified-newusername',
                    name: 'founded-not-verified',
                    component: FoundedNotVerified
                }
            ],
            redirect: {
                name: 'signin'
            }
        },
        {
            path: '*',
            redirect: {
                name: 'signin'
            }
        }
    ]

})

router.afterEach((to: any, from: any) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE
    })
})

export default router