export default {
    title: "Профиль",
    farms_info: "Шаруашылықтар туралы ақпарат",
    templates: {
        my_title: "Менің үлгілерім",
        no_templates: "Әлі үлгілер жоқ",
        new_sugesstion: "Тез арада технолгиялық карталарда қолдану үшін жаңа үлгілер құрыңыз",
        new_button: "Үлгі құру",
        name: "Атауы",
        name_input: "Үлгінің атауын енгізіңіз"
    },
    edit: {
        title: "Профильді редакциялау",
        profile_editing: {
            title: "Профильді редакциялау",
            changed: "Деректер өзгертілді",
            success: "Профиль деректері сәтті өзгертілді"
        }
    },
    subscriptions: {
        title: ["Менің жазылымдарым", "Менің тапсырыстарым", "Тапсырыстар"],
        agrochem: "Топырақтың агрохимиялық талдауы: Шаруашылығы",
        ready: "Нәтижелер дайын",
        sent: "Мәлімдеме жіберілді",
        paid: "Төленді",
        not_paid: "Төленбеген"
    },
    advertisement: {
        welcome: "Egistic Limited компаниясына қош келдіңіз!",
        finishes_after: "Акциялық кезең...арқылы аяқталады:",
        days: " күн ",
        hours: " сағат ",
        minutes: " минут ",
        seconds: " секунд",
        free_text: [
            "Құттықтаймыз! Сіз 14 күн бойы тегін жазылымды іске қостыңыз.",
            "Тегін пайдалану кезеңінде сізге қолжетімді болады:",
            "・ Технологиялық карта жасау мүмкіндігі бар агрономның сандық журналы",
            "・ Ғарыштық суреттер бойынша алаңды талдау (вегетация деңгейі, проблемалық аймақтар, егістіктің ластануы, түсімділік болжамы)",
            "・ GPS тректер бойынша техника телеметикасының модулі",
            "・ Агроскаутинг модулі",
            "Сіз сондай-ақ бөлімде жылдық жазылымды рәсімдей аласыз \"Шаруашылығы\"."
        ]
    },
    staff: {
        title: "Қызметкерлер",
        n_employee: "Бір қызметкер | {n} қызметкер | {n} қызметкерлер",
        add_title: "Қызметкерді қосу",
        name: "Қызметкердің аты",
        access: "Қызметкердің кіру түрі: ",
        open_access: "Қолжетімділікті ашу",
        full: "Толық",
        restricted: "Шектеулі",
        surname: "Қызметкердің тегі",
        fullname: "Қызметкердің аты-жөні",
        email: "Email",
        number: "Телефон нөмірі",
        notification: "Хабарлама түрі",
        assign_manager: "Директор қызметіне орнату"
    },
    service_book: {
        title: "Сервистік кітабы",
        details: "Толығырақ",
        record_adding: {
            title: "жазба"
        }
    },
    storage: {
        title: "Қойма",
        storage_search: "Қойма бойынша іздеу",
        storage_remains: "Қойма бойынша қалдықтар",
        operation: "әрекеті"
    },
    password_change: {
        title: "Смена пароля",
        password_changing: {
            current_password: "Ағымдағы құпиясөз",
            new_password: "Жаңа құпиясөз"
        }
    },
    farms: {
        table: {
            cadastre_number: "Кадастр нөмірі",
            area: "Ауданы",
            fields: "Ұяшықтар",
            arable_land: "Егістік алқабы, га",
            pasture: "Жайылым, га",
            haymaking: "Шөп шабу, га",
            others: "Өзгеше, га",
            registration_date: "Тіркеу күні",
            registration_number: "Договор №",
            agreement_date: "Шарт мерзімі"
        }
    }
}