

















































import { Component, Vue } from 'vue-property-decorator'
import '@/assets/sounds/notification.wav'
@Component({
    components: {
    }
})
export default class ContactModel extends Vue {
    private isPhoneShown = true
    private isContactsShown = false
}
