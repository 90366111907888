







































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import {apiUrl, COOKIE_KEY_AUTH_HEADER, openCrossOriginWebSite} from '@/domain/constants'
import { httpPost, httpGet } from '@/util/http'
import { Action, Mutation, Getter } from 'vuex-class'
import { GetterNames, MutationNames, ActionNames } from '@/store/types'
import i18n from '@/i18n'
import VuePhoneNumberInput from "vue-phone-number-input"

@Component({
    components: {
        VuePhoneNumberInput
    }
})
export default class Sign extends Vue {
    @Mutation(MutationNames.setSignError) private setSignError !: any
    @Mutation(MutationNames.changeNeedToConfirmMail) private changeNeedToConfirmMail !: any
    @Action(ActionNames.ON_SIGN) private onSignedInAction !: any
    @Getter(GetterNames.getSignViewData) private getSignViewData !: any
    private username = ''
    private countryCode = ''
    private password = ''
    private passwordFormError = ''
    private showLoginPassword = false
    private isLogining = false
    private ischecked = true

    // enter by email
    private email: any = null
    private numberAsUsername: any = null

    // enter by phone
    private number: any = null

    // modal page
    private loginPhone = true
    private loginEmail = false

    // login by email and number === null
    private enterByEmailNotVerifiedNumber: any = false

    @Watch('enterByEmailNotVerifiedNumber')
    private watchLoginPhone(val: any) {
        if (val) {
            if (this.number !== null) {
                Vue.alert(`${this.$t('headers.auth.signin.confirmPersonWithNumber')}`, `${this.$t('headers.auth.signin.successSignUp')}`, 'success')
            } else {
                Vue.alert(`${this.$t('headers.auth.signin.confirmPersonWithoutNumber')}`, `${this.$t('headers.auth.signin.successSignUp')}`, 'success')
            }
        }
    }

    private mounted() {
        if (this.$route.params.exist === 'success') { // new User successfully -> Verified -> set New password -> come to Log In
            Vue.alert(`${this.$t('headers.auth.signin.successSignIn')}`, `${this.$t('headers.auth.signin.successSignUp')}`, 'success')
        }
        if (this.$route.params.exist === 'onlyPhone') {
            Vue.alert(`${this.$t('headers.auth.signin.successSignIn')}`, `${this.$t('headers.auth.signin.successVerification')}`, 'success')
        }
    }

    private loginEnterPhone() {
        if (this.loginPhone) return
        this.loginEmail = false
        this.loginPhone = true
    }

    private loginEnterEmail() {
        if (this.loginEmail) return
        this.loginPhone = false
        this.loginEmail = true
    }

    private updatePhoneNumber(data: any) {
        this.username = data.formattedNumber
        this.countryCode = data.countryCallingCode
    }

    private signButton() {
        this.$gtag.event('auth: login/register')
        if (this.number === '' || (this.number.length !== 12 && this.number.length !== 13 && this.number.length !== 9)) {
            return Vue.alert('Введите корректный номер', 'Ошибка', 'warning')
        }
        const normalizeNumber = ('+' + this.countryCode + this.number).split(' ').join('')
        console.log(normalizeNumber)
        this.fetchSignInNumber(normalizeNumber)
    }

    private fetchSignInNumber(data: any) {
        this.isLogining = true
        httpPost({
            url: `${apiUrl}/phone/`,
            detailResponse: true,
            isSecureRequest: true,
            body: {
                phone: data
            },
            onSuccess: json => {
                console.log(json.json, 'json')
                if (json.ok) {
                    localStorage.setItem('username', data)
                    console.log(localStorage.getItem('username'))
                    console.log(json.json)
                    switch (json.json.status) {
                        case 'founded and verified': /// signIn
                            localStorage.setItem('password', this.password)
                            this.$router.push({name: 'password-signin', params: { username: data, password: this.password } })
                            break
                        case 'founded but not verified':
                            if (!this.enterByEmailNotVerifiedNumber) { // если сразу вводить номер
                                this.$router.push({name: 'founded-not-verified'})
                                break
                            }
                            localStorage.setItem('email', this.email) // если ввел почту а потом перешел сюда
                            localStorage.setItem('type', '3')
                            this.$router.push({name: 'sms-code', params: { number: data, type: '3', email: this.email}})
                            break
                        case 'not founded': // sms verification -> code on number
                            console.log(this.enterByEmailNotVerifiedNumber)
                            if (this.enterByEmailNotVerifiedNumber) { // если он пришел сразу с почты
                                localStorage.setItem('email', this.email)
                                localStorage.setItem('type', '3')
                                this.$router.push({name: 'sms-code', params: { number: data, type: '3', email: this.email }})
                            } else {
                                localStorage.setItem('type', '1')
                                this.$router.push({ name: 'sms-code', params: { number: data, type: '1' } })
                            }
                            break
                        case 'verified': // sms vefiried checked -> must set password
                            if (this.enterByEmailNotVerifiedNumber) {
                                 Vue.alert('Данный номер уже есть под другим пользователям', 'Номер занят', 'warning')
                                 this.enterByEmailNotVerifiedNumber = false
                            }
                            localStorage.setItem('type', '1')
                            this.$router.push({name: 'password-signup', params: {phone: data, exist: 'exist'}})
                    }
                }
                this.passwordFormError = `Некорректный номер телефона`
            },
            onError: error => {
                this.passwordFormError = i18n.t('headers.auth.signin.errors.google') as string
                console.log(error)
            },
            doFinally: () => {
                this.isLogining = false
            }
        })
    }

    private signEmail() {
        httpPost({
            url: `${apiUrl}/signin/new/`,
            body: {
                username: this.email,
                password: this.password
            },
            onSuccess: json => {
                console.log(json)
                if (!json.detail) {
                    this.loginEmail = false
                    this.loginPhone = true
                    this.enterByEmailNotVerifiedNumber = true
                    if (json.phone) {
                        console.log(json.phone)
                        this.number = json.phone
                        this.numberAsUsername = json.phone
                        Vue.alert(`${this.$t('headers.auth.signin.confirmPersonWithNumber')}`, `${this.$t('headers.auth.signin.confirmPerson')}`, 'success')
                        return
                    } else if (json.phone === null) {
                        Vue.alert(`${this.$t('headers.auth.signin.confirmPersonWithoutNumber')}`, `${this.$t('headers.auth.signin.confirmPerson')}`, 'success')
                        return
                    }
                    console.log(33)
                    this.loginEmail = false
                    this.loginPhone = true
                    this.number = json.user.username
                    Vue.alert(`${this.$t('headers.auth.signin.confirmPersonText')}`, `${this.$t('headers.auth.signin.confirmPerson')}`, 'success')
                } else {
                    this.passwordFormError = 'Неправильно введен логин или пароль'
                }
            },
            onError: error => {
                this.passwordFormError = i18n.t('headers.auth.signin.errors.typo') as string
                console.log(error)
            },
            doFinally: () => {
                this.isLogining = false
            }
        })
    }

    @Watch('username')
    private usernameChanged() {
        this.passwordFormError = ''
    }

    @Watch('password')
    private passwordChanged() {
        this.passwordFormError = ''
    }

    private forgotPassword() {
        const route: any = {
            name: 'password_reset'
        }
        if (this.$route.params.redirect) this.$route.params.redirect = this.$route.params.redirect
        this.$router.push(route)
    }

    private openLandingPage() {
        openCrossOriginWebSite('landing')
    }

    // sign in by google
    private async googleSignIn() {
        console.log(this.$gAuth)
        await this.$gAuth.signIn().then((GoogleUser: any) => {
            console.log(GoogleUser)
            console.log(GoogleUser.getId())
            console.log(GoogleUser.getBasicProfile())
            const accessToken = GoogleUser.getAuthResponse().access_token
            if (accessToken) {
                this.isLogining = true
                httpPost({
                    url: `${apiUrl}/social/google-oauth2/`,
                    body: {
                        access_token: accessToken
                    },
                    onSuccess: json => {
                        console.log(json)
                        this.saveToken(json) // here token save
                        this.checkOnVerifiedByGoogle()
                        this.changeNeedToConfirmMail(false)
                    },
                    onError: error => {
                        this.passwordFormError = i18n.t('headers.auth.signin.errors.google') as string
                        console.log(error)
                    },
                    doFinally: () => {
                        this.isLogining = true
                    }
                })
            }
        })
    }

    private saveToken(json: any) {
        localStorage.removeItem('username')
        localStorage.removeItem('password')
        const tokenExpireDate = new Date()
        let tokenExpireMillis = 1800000
        if (json.expires_in) {
            tokenExpireMillis = parseFloat(json.expires_in) * 1000
        }
        tokenExpireDate.setTime(tokenExpireDate.getTime() + tokenExpireMillis)
        this.onSignedInAction({
            token: json.token,
            expiresIn: tokenExpireDate.getMilliseconds()
        })
    }

    private onSignedIn() {
        if (this.$route.params.redirect) {
            openCrossOriginWebSite(this.$route.params.redirect)
        } else openCrossOriginWebSite('landing')
    }

    private checkOnVerifiedByGoogle() {
        this.isLogining = true
        httpGet({ // check for user_info -> phone
            url: `${apiUrl}/user_info/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                console.log(json)
                if (json.ok) {
                    console.log(json)
                    this.checkNumberOnGoogleAccount(json)
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.isLogining = false
            }
        })
    }


    private checkNumberOnGoogleAccount(data: any) {
        console.log(data, 'check google account')
        httpPost({ // check status user_info -> username
            url: `${apiUrl}/phone`,
            detailResponse: true,
            isSecureRequest: true,
            body: {
                phone: data.json.user.username
            },
            onSuccess: json => {
                console.log(json, 'json')
                switch (json.json.status) {
                    case 'founded and verified':
                        this.onSignedIn()
                        break
                    case 'not founded':
                        console.log(json, 'not founded')
                        if (!data.json.user.username.includes('+7')) {       // username === email
                            this.numberAsUsername = data.json.user.username
                            this.loginEmail = false
                            this.loginPhone = true
                            this.enterByEmailNotVerifiedNumber = true
                            break
                        }                                                    // username === phone
                        this.numberAsUsername = data.json.user.username
                        this.number = data.json.json.username
                        this.loginEmail = false
                        this.loginPhone = true
                        this.enterByEmailNotVerifiedNumber = true
                        break
                }
            },
            onError: error1 => {
                console.log(error1)
            }
        })
    }

}
