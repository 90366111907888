










import { Component, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import { MutationNames } from './store/types'
import { Mutation } from 'vuex-class'
import { setLastLanguage } from '@/domain/constants'
import Bowser from "bowser"
@Component
export default class App extends Vue {
    @Mutation(MutationNames.setCurrentRoute) private setCurrentRoute!: any

    private mounted() {
        const browser = Bowser.parse(window.navigator.userAgent)
        if (browser) {
            this.$ga.event('browser', browser.browser.name, JSON.stringify(browser), 1)
        } else {
            this.$ga.event('browser', 'unknown', JSON.stringify(browser), 1)
        }
    }

    @Watch('$root.$i18n.locale', { immediate: true, deep: true })
    private onchangeLocal(newLang: any, oldLang: any) {
        if (newLang !== oldLang) {
            setLastLanguage(newLang)
        }
    }

    @Watch('$route', { immediate: true, deep: true })
    private onUrlChange(route: Route, old: Route) {
        this.setCurrentRoute(route)
    }
}
