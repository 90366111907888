export default {
    title0: "Кабинетке кіру",
    title: "Кабинетке кіру / Тіркелу",
    register: "Немесе | Тегін тіркеліңіз",
    number: "Телефон нөмірі немесе e-mail",
    numberOnly: "Телефон нөмір",
    remember: "Мені есте сақтау",
    password_forgotten: "Құпия сөзді ұмыттыңыз ба?",
    email_confirm: "Поштаны растау",
    password_reset: {
        title: "Құпиясөзді қалпына келтіру",
        explanation_1: "Құпиясөзді қалпына келтіру үшін email көрсетіңіз",
        notification: "Сіздің поштаңызға  | растау сілтемесі жіберілді. Кіру үшін оған өтіңіз.",
        explanation_2: "Жаңа құпиясөз ойлап табыңыз",
        new_password: "Жаңа құпиясөз",
        password_repeat: "Құпиясөзді растау",
        success: "Құпиясөз сәтті өзгертілді!",
        message: "Жаңа құпия сөзді пайдаланып кіріңіз"
    },
    errors: {
        google: "Google account проблемасы, қайталап көріңіз",
        typo: "Логин немесе құпиясөз дұрыс енгізілмеген",
        email: "Email-a пішімі қате",
        not_found: "Email деректері бар пайдаланушы табылмады",
        server: "Сервер қатесі",
        data: "Деректер дұрыс енгізілмеді",
        password: "Пароль кемінде 6 таңбадан тұруы керек"
    },
    loginSmsSubtitle: "Кабинетке кіру үшін пароль жазыңыз",
    next: "Жалғастыру",
    codeOnSms: "SMS-тен кодты енгізіңіз",
    subtitleCodeOnSms: "Біз растау кодын нөмірге жібердік",
    code: "Кодты енгізіңіз",
    passwordSignUpSuccess: "Сіз SMS растауды сәтті өттіңіз. Құпия сөзді орнатыңыз",
    passwordSignUpAlready: "Нөмір SMS верификациясынан өткен. Құпия сөзді орнатыңыз",
    successSignUp: "Сіз сәтті тіркелдіңіз",
    successSignIn: "Кіру үшін нөмірінізді енгізіңіз",
    successVerification: "Сіз тексеруден сәтті өттіңіз",
    confirmPerson: "Жеке басын растау",
    confirmPersonText: "Біз кіру және тіркеу жүйесін жаңартамыз. Қазір телефон нөмірі арқылы кіруге болады, өйткені сіз email арқылы тіркелдіңіз, телефон нөміріңізді көрсетуіңізді сұраймыз",
    confirmPersonWithNumber: "Біз кіру және тіркеу жүйесін жаңартамыз. SMS тексеру үшін Келесі түймесін басыңыз. Бұдан әрі сайтқа кіру көрсетілген нөмір арқылы жүзеге асырылады",
    confirmPersonWithoutNumber: "Біз кіру және тіркеу жүйесін жаңартамыз. SMS верификация үшін телефон нөмірін көрсетіңіз. Бұдан әрі сайтқа кіру көрсетілген нөмір арқылы жүзеге асырылады",
    confirmPersonChangeUsername: "Біз кіру және тіркеу жүйесін жаңартамыз. Нөмірге жіберілген SMS кодын көрсетіңіз"
}