export default {
    guide: "Проводник",
    cell: {
        edit: "Редактировать клетку",
        add: "Добавить клетку",
        delete: "Удалить клетку",
        combine: "Объединить клетки",
        split: "Разделить клетку",
        info: "Информация о клетке"
    },
    vertix: {
        delete: "Удалить вершину",
        delete_several: "Удалить несколько вершин"
    },
    hole: {
        create: "Создать дыру"
    },
    farm: {
        new: "Новое хозяйство"
    },
    close: "Закрыть",
    back: "Назад",
    forward: "Вперед",
    learn_more: "Подробнее",
    reset: "Сбросить",
    add: "Добавить",
    edit: "Редактировать",
    change: "Изменить",
    settings: "Настройки",
    add_note: "Добавить запись",
    add_storage: "Добавить склад",
    add_operation: "Добавить операцию",
    delete: "Удалить",
    print: "Распечатать",
    exit: "Выйти",
    tile: "Плитка",
    table: "Таблица",
    search: "Поиск",
    steps: {
        prev: "Предыдущий",
        next: "Cледующий"
    },
    download: {
        title: "Скачать",
        excel: "Скачать Excel"
    },
    choose: {
        map: "Выбрать на карте"
    },
    employees: "Сотрудники",
    subscribe: "Оформить подписку",
    actions: "Действия",
    sort: "Сортировка",
    broadcasting: "Телематика",
    service_book: "Сервисная книга",
    manage_notifs: "Настроить уведомления"
}