export default {
    title: "Ұяшықтар",
    field: "Ұяшық",
    text: "Әр жер бірегей. Осы бөлімде дақыл мен себу мерзімдерін көрсеткеннен кейін сіз өрісіңіздің жағдайы туралы ең көп ақпарат аласыз.",
    info: {
        crops: "Өсірілетін дақыл",
        crop: "Дақыл",
        name_farmer: "Агрономның аты",
        num: "Нөмері",
        area: "Алаңы",
        season: "Маусымы",
        sort: "Сорт",
        reproduction: "Репродукция",
        date: "Егу күні",
        date_cleaning: "Тазалау күні",
        vegetation: "Вегетация кезеңі",
        data: "Деректер",
        settings: "Қолжетімділік параметрлері"
    },
    edit: {
        title: "Паспортты өңдеу",
        name: "Агрономның аты-жөні | Аты",
        area: "Алаңы, га"
    },
    history: {
        title: "Жердің тарихы",
        predecessor: "Бастамашы:",
        yield: "Өнімділік:",
        steam: "Парлар",
        methods: "Өңдеу әдістері",
        year: "Жыл",
        edit: "Тарихты өзгерту",
        add: "Тарихты қосу"
    },
    indicators: {
        title: "Көрсеткіштер",
        indicator: "Көрсеткіш",
        phosphorus: "Фосфор",
        potassium: "Калий",
        nitrogen: "Азот",
        sulfur: "Сера",
        humus: "Көң",
        info: {
            notes: "Өткен суреттерді салыстыра отырып, біздің жүйе проблемалық аймақтарды анықтады. Жазбаға жазуға кеңес береміз.",
            empty: "Сізде әлі өңделген тапсырыстар жоқ"
        },
        data: {
            title: "Деректер",
            data_from: "Деректерден",
            types: ["Деректерді салыстыру хроникасы", "Проблемалық аймақтар", "Ластану деңгейі"]
        },
        indicators: {
            vegetation: {
                title: "Вегетация",
                text: "«Биомасса көлемін бағалау картасы» немесе <br> «вегетациялық индекс» <br>тығыз өсімдік жамылғысы жағдайларында да, <br>және сирек өсімдіктер жағдайларында да өсімдіктердің жай-күйін бағалауға мүмкіндік береді. <br> Осы міндетті орындау үшін <br>өсімдіктердің қалыпты салыстырмалы индексі <br>(NDVI) пайдаланылды, ол ең көп таралған және пайдаланылатын <br> индекстерінің бірі болып табылады."
            },
            nitrogen: {
                title: "Азот",
                text: "«Өсімдіктердің <br>жапырақтарындағы азот құрамының картасы» кадастрлық өріс шекарасы шегінде <br>егістер жай-күйінің проблемалы аймақтарын, сондай-ақ <br>ауыл шаруашылығы дақылдары шығымдылығының <br>модельдеріндегі бастапқы параметр ретінде <br>анықтауға мүмкіндік береді"
            },
            humidity: {
                title: "Ылғалдылық",
                text: "«Ылғал құрамының картасы» немесе «топырақ беті ылғалдылығының <br>индексі» <br>өсімдіктер мен топырақтың ылғалдану <br>дәрежесінің әртектілігін бағалауға мүмкіндік береді. Берілген <br > индекс өсімдіктердің топырағы мен жапырақтарындағы <br>ылғал құрамына аса сезімтал. <br>осы міндетті орындау үшін <br>топырақ пен <br>өсімдіктер ылғалдылығының индексі пайдаланылды. Судың қалыпты дифференциалды индексі және топырақ пен жапырақтардың ылғал мөлшері <BR> өсімдіктер."
            },
            chlorophyll: {
                title: "Хлорофил",
                text: "«Өсімдіктердегі хлорофилл құрамының картасы» <br>немесе «өсімдіктер денсаулығының индексі»  <br>кадастрлық алқап шекарасы шегіндегі егістіктердің проблемалық аймақтарын <br>, сондай-ақ <br>ауыл шаруашылығы дақылдарының шығымдылығын болжаудағы бастапқы параметр ретінде <br>анықтауға мүмкіндік береді. <br> Хлорофилл деңгейі өсімдік орналасқан Фотосинтездің <br > фазасын көрсетеді, <br>бұл өз кезегінде өсімдіктің <br>жетілуін көрсетеді."
            }
        },
        fertilization: {
            title: "Тыңайтқыш енгізу",
            map: "Азот тыңайтқыштарын енгізуге арналған карта",
            vegetation_zone: "вегетациялық аймақ",
            avg_value: "орташа мән",
            rate: "енгізу нормасы",
            total: "Барлығы",
            mass: "кг тыңайтқыш"
        },
        report: {
            title: "Есептеме",
            problem_zones: "Проблемдық зоналар бойынша есептеме",
            weediness: "Ластану дәрежелері бойынша есептеме",
            pz_n_w: "Проблемдық зоналар және Ластану дәрежелері бойынша есептеме"
        }
    },
    chronicle: {
        title: "Хроникасы",
        by_orders: "Тапсырыс бойынша",
        by_weather: "Ауа райы бойынша",
        not_show: "Көрсетілмесін"
    },
    yield: {
        title: "Өнімділік",
        type: "Өнімділікті енгізіңіз",
        forecast: "Өнімділік болжамы"
    },
    documents: {
        title: "Құжаттар",
        add: "Құжатты қосу"
    },
    notes: {
        title: "Белгілер",
        empty: "Жазбаны жасау үшін картаны тінтуірмен басыңыз",
        date: "Күні",
        time: "Сағат",
        note_addition: {
            title: "Жазбаны қосу",
            problem: {
                title: "Табылған мәселе",
                type1: "Ауру",
                type2: "Зиянкес",
                type3: "Арамшөп",
                type4: "Басқалар"
            },
            note: {
                title: "Жазба мәтіні",
                text: "Жазуды бастаңыз..",
                notice: "Қызметкерді белгілеу",
                search: "Қызметкерді іздеу",
                table_note: "Примечание (скорость ветра, облачность, температура, тип опрыскивателя и т.д.)"
            },
            problem_details: {
                type1: {
                    title: "Ауру түрі",
                    epv: "ЭПВ, фаза",
                    indicator: "Развитие, (%)",
                    input1: {
                        placeholder: "Ауру түрін таңдаңыз"
                    },
                    input2: {
                        header: "Даму, %",
                        placeholder: "Даму дәрежесін енгізіңіз"
                    },
                    advice: {
                        header: "Фунгицид",
                        placeholder: "Фунгицидті таңдаңыз"
                    },
                    add: "Ауру түрін қосу",
                    add_prep: "Фунгицид қосу"
                },
                type2: {
                    title: "Зиянкес түрі",
                    epv: "ЭПВ, шт/м²",
                    indicator: "Заселение, (шт/м²)",
                    input1: {
                        placeholder: "Зиянкес түрін таңдаңыз"
                    },
                    input2: {
                        header: "Орналасу, шт/м²",
                        placeholder: "Көрсеткішті еңгізіңіз"
                    },
                    advice: {
                        header: "Жәндікжойғы",
                        placeholder: "Жәндікжойғыны таңдаңыз"
                    },
                    add: "Зиянкес түрін қосу",
                    add_prep: "Жәндікжойғы қосу"
                },
                type3: {
                    title: "Арамшөп түрі",
                    epv: "ЭПВ, шт/м²",
                    indicator: "Степень засоренности (Х)",
                    input1: {
                        placeholder: "Арамшөп түрін таңдаңыз"
                    },
                    input2: {
                        header: "Ластану дәрежесі",
                        placeholder: "Ластану дәрежесін енгізіңіз"
                    },
                    input3: {
                        header: "Мөлшері, м²",
                        placeholder: "Мөлшерін енгізіңіз"
                    },
                    advice: {
                        header: "Гербицидтер",
                        placeholder: "Гербицидті таңдаңыз"
                    },
                    add: "Арамшөп түрін қосу",
                    add_prep: "Гербицид қосу"
                },
                advice_title: "Өңдеу бойынша кеңес"
            },
            treatment_date: "Өңдеу уақыты",
            treatment_method: "Өңдеу жолы",
            photo: "Суреттер",
            photo_info: "Сурет жайында ақпарат",
            added_by: "Суретті қосқан",
            added_from: "Қосылған құрылғы",
            from_pc: "Жеке компьютер",
            from_phone: "Телефон",
            date: "Айналып өту күні",
            phen_stage: "Фенологиялық фаза",
            send: "Хабарлама жіберу",
            create: "Ескерту бойынша іс-шара қосу"
        },
        reply: {
            title: "Жазбаларға жауабы",
            text: "Жауап мәтіні",
            from: "Жауап"
        },
        note_editing: "Жазбаны өңдеу",
        note_details: {
            title: "Жазба",
            consumption_rate: "Тұтыну коэффициенті",
            processing_method: "Өңдеу әдісі",
            target: "Нысана ",
            creation_date: "Жазылу уақыты"
        }
    },
    tasks: {
        title: "Есептер",
        apply: "Ұяшыққа қолдану",
        without_crop: "Дақылсыз",
        event: "Іс-шара",
        event_dates: "Іс шара мерзімдері",
        field_num: "Ұяшық бойынша тапсырмалар #",
        event_addition: {
            title: "Жаңа іс-шара",
            general: {
                title: "Негізгі санаттар",
                name: "Іс-шараның атауы",
                date: "Іс-шара күні",
                cost: "Іс-шараның құны"
            },
            additional: {
                title: "Қосымша санаттар",
                machine: "Агрегат",
                speed_limit: "Жылдамдық лимиті",
                staff: "Қызметкер",
                output: "Өндіру",
                fuel: "Жанармай",
                name: "Атауы",
                amount: "Саны",
                unit: "Өлшеу бірлігі",
                send: "Телематикаға жіберу:"
            }
        },
        event_change: {
            edit: "Іс-шараны редакциялау",
            new: "Жана іс-шара",
            update: "Іс-шараны жаңарту",
            add: "Іс-шара қосу"
        },
        new_category: "Жана категория",
        types: ["Егіс айналымы", "Топырақты баптау жүйесі", "Тыңайтқыштар жүйесі", "Тұқым шаруашылығы жүйесі", "Арамшөппен күрес", "Зиянкестер мен аурулардан қорғау", "Топырақ эрозиясымен күрес"]
    },
    analytics: {
        title: "Аналитика",
        rating: "Ұяшықтың рейтингі",
        num_rating: "Рейтингтегі нөмірі",
        avg_value: "ұяшықтың орташа мәні",
        by_crop: "Дақыл бойынша аналитика:",
        date: "Маусымның басталу күні",
        compare_seasons: "Мезгілдерін салыстыру",
        cur_season: "Ағымдағы маусым: ",
        no_season: "Маусым таңдалмаған",
        graphs: ["Белсенді температура мен жауын-шашынның қосындысы", "Белсенді температуралардың қосындысы", "Жинақталған жауын-шашын қосындысы"]
    },
    delete: "Ұяшықты алып тастау",
    delete_field: {
        title: "Субполяны алып тастау",
        text: "Сіз субполаны алып тастағыңыз келе ме?"
    },
    crop_rotation: {
        title: "Егіс айналымы",
        data: "Деректер",
        spring: {
            title: "Көктемгі өңдеу",
            choose: "Көктемгі өңдеуді таңдаңыз немесе жазыңыз"
        },
        fall: {
            title: "Күздік өңдеу",
            choose: "Күздік өңдеуі таңдаңыз немесе жазыңыз"
        }
    }
}